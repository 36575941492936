import React, { useState, useEffect } from 'react';
import firebase from '../Firebase';
import TodoItem from './TodoItem'
import './TodoList.scss'





function TodoList() {

    const [list, setList] = useState([]);
    const [todo, setTodo] = useState("");
    const [error, setError] = useState("");


    const fetchTodos = async() => {        
        const db = firebase.firestore();        
        const response = db.collection('todos');        
        const data = await response.get();

        //Load all todos
        setList(data.docs.map(item => {
            return {
                "id": item.id,
                ...item.data()
            }
        }));
        
    }

    useEffect(() => {
        fetchTodos();
    }, [])


    const handleChange = (event) =>{
        event.preventDefault();
        setTodo(event.target.value);
        setError("");
    }

    const addTodo = async (event) =>{
        let hasErr = false; 

        if(todo.length <= 0 ){
            setError("Todo value can't be empty");
            hasErr = true
        }

        if(!hasErr){
            event.preventDefault();
            const db = firebase.firestore();
            const addTodo =  await db.collection("todos").add({
                todoItem: todo,
                completed: false
              }); 
            fetchTodos();
            setTodo("");
            setError("");
        }
    }

    const completeTodo = (id, todoText, completed) => {
        const db = firebase.firestore();
        db.collection("todos").doc(id).set({
            todoItem: todoText,
            completed: !completed
        });
        fetchTodos();
    }

    const deleteItem = (id, event) =>{
        event.preventDefault();
        const db = firebase.firestore();
        db.collection("todos").doc(id).delete();
        fetchTodos();
    }


    return (
        <div className="container">
            <h1>Todo List</h1>
            <p>This is a simple todolist app with ReactJS + FireBase</p>
   
            <div className="add-todo-container">
                <input 
                    className="input" 
                    type="text" 
                    placeholder="Enter a todo item here"
                    value={todo}
                    onChange={
                        e => handleChange(e)
                    }
                />

                <button 
                    className="button"
                    onClick={
                        e => addTodo(e)
                    }                    
                >Add To Do</button>
            </div>
            <div className="errMessage">{error}</div>
            <div className="list-container">
                {
                    list && list.map((item) => {
                        return (<TodoItem
                                    key={item.id} 
                                    todoText={item.todoItem}
                                    id={item.id}
                                    deleteItem={deleteItem}
                                    completed={item.completed}
                                    completeTodo={completeTodo}
                                />);
                    })
                }               
            </div>
            
        </div>
    )
}

export default TodoList
