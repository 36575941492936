import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCP2a0x7jPlwc_CrOM8Rlpod0zh5jVMFiw",
    authDomain: "simple-todo-app-ee6ae.firebaseapp.com",
    projectId: "simple-todo-app-ee6ae",
    storageBucket: "simple-todo-app-ee6ae.appspot.com",
    messagingSenderId: "213498307781",
    appId: "1:213498307781:web:0a78ee61f19d8aaa2c3cac"
  };

// Initialize Firebase
firebase.initializeApp(config);


export default firebase;