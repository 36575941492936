import React from 'react'
import './TodoItem.scss'

function TodoItem(props) {


    const {todoText, id, completed, completeTodo, deleteItem} = props;

    return (
        <div 
            className={completed ? "listItem completed" : "listItem"}

        >
            <div className="todo-text">
                {todoText}
            </div>

            <div className="todo-buttons">

                <button
                    className="delete-btn"                 
                    onClick={
                        event => deleteItem(id, event)
                    }
                >Delete</button>

                <button 
                    className="complete-btn"
                    onClick={
                        event => completeTodo(id, todoText, completed)
                    }
                >
                    {completed ? "Undo" : "Complete"}
                </button>

            </div>

        </div>
    )
}

export default TodoItem
